import React, {useState, useEffect} from "react";
import {StandaloneSearchBox} from "@react-google-maps/api";
import {GSPSLabelledInput} from "Components/Common/GSPSLabelledInput";

export const GooglePlaces = ({
    setValue,
    caller,
    id,
    register,
    isRequired,
    errors
}) => {
    const [inputValue, setInputValue] = useState("");
    const [searchBox, setSearchBox] = useState(null);
    const [error, setError] = useState("");
    function setAddressValues(address) {
        setValue("address.latitude", address.latitude || "");
        setValue("address.longitude", address.longitude || "");
        setValue("address.place_api_id", address.place_api_id || "");
        setValue("address.apt_num", address.apt_num || "");
        setValue("address.street_num", address.street_num || "");
        setValue("address.street_name", address.street_name || "");
        setValue("address.city", address.city || "");
        setValue("address.state", address.state || "");
        setValue("address.zip", address.zip || "");
    }
    useEffect(() => {
        if (caller && caller.address) {
            setAddressValues(caller.address);
        }
        if (caller && Object.keys(caller).length > 0) {
            const addressFields = caller[id] || {};
            const {apt_num, street_num, street_name, city, state, zip} = addressFields;
            const fullAddress = [apt_num, street_num, street_name, city, state, zip].filter(Boolean).join(" , ");
            setInputValue(fullAddress);
        }
    }, [caller, id]);

    const handlePlaceChanged = () => {
        const places = searchBox.getPlaces();
        if (places && places.length > 0) {
            const place = places[0];
            const addressComponents = place?.address_components?.reduce((acc, component) => {
                const types = component.types;
                if (types.includes("street_number")) acc.street_num = component.long_name;
                if (types.includes("route")) acc.street_name = component.long_name;
                if (types.includes("postal_code")) acc.zip = component.long_name;
                if (types.includes("locality")) acc.city = component.long_name;
                if (types.includes("administrative_area_level_1")) acc.state = component.long_name;
                if (types.includes("country")) acc.country = component.short_name;
                if (types.includes("subpremise")) acc.apt_num = component.long_name;
                return acc;
            }, {});

            const address = {
                latitude: place.geometry.location.lat(),
                longitude: place.geometry.location.lng(),
                place_api_id: place.place_id,
                ...addressComponents,
            };

            if (!address.street_num && !address.street_name && !address.city && !address.state) {
                setError("Please enter a more specific address.");
                const defaultAddress = {
                    "latitude": "",
                    "longitude": "",
                    "place_api_id": "",
                    "apt_num": "",
                    "street_num": "",
                    "street_name": "",
                    "city": "",
                    "state": "",
                    "zip": "",
                };
                setAddressValues(address ?? defaultAddress);
                return;
            }
            setError("");
            setAddressValues(address);
        }
    };

    return (
        <>
            {
                (caller ? inputValue : true) &&
                <StandaloneSearchBox
                    onLoad={setSearchBox}
                    onPlacesChanged={handlePlaceChanged}
                >
                    <GSPSLabelledInput
                        value={inputValue}
                        labelName={"Address"}
                        id={"addressInput"}
                        controlType={"text"}
                        isRequired={isRequired}
                        placeHolder={"Select the location"}
                        register={register}
                        errors={errors}
                    />
                </StandaloneSearchBox>
            }
            {error && <div style={{color: "red"}}>{error}</div>}
        </>
    );
};
