import React, {useEffect, useState} from "react";
import Modal from "react-bootstrap/Modal";
import {Grid} from "@mui/material";
import {SendReportForm} from "Components/Report Sending/SendReportForm";

export const EmailPopup = () => {

    const [isPreviewDisplayed, setIsPreviewDisplayed] = useState(true);
    const handleClose = () => setIsPreviewDisplayed(false);

    return (
        <Modal className="modal show"
            backdrop="static"
            onHide={handleClose}
            show={isPreviewDisplayed}
            centered
            container={document.querySelector("#root .App")}
        >
            <Modal.Header closeButton>
                <h5>Send Report</h5>
            </Modal.Header>
            <Modal.Body>
                <Grid container spacing={2}>
                    <SendReportForm onSent={handleClose} />
                </Grid>
            </Modal.Body>
        </Modal>
    );
};
