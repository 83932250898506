import React, {useEffect, useState} from "react";
import {createSelectionOptions} from "Services/ServicesUtils";
import PropTypes from "prop-types";
import {GSPSToolbarSelectField} from "Components/Common/GSPSToolbarSelectField";
import {ToolbarContainer} from "./AuditMapToolbar.styles";
import {Button} from "@mui/material";
import CheckBoxOutlinedIcon from "@mui/icons-material/CheckBoxOutlined";
import {useSelector} from "react-redux";
import {GSPSControlledLabelledSelectField} from "Components/Common/GSPSControlledLabelledSelectField";
import {GSPSMapFilter} from "Components/Map/GSPSMapFilter";

const AuditDeletionOptionsList = [
    {key: "deleteIssues", value: 1, display_text: "Delete Items"},
    {key: "deleteLines", value: 2, display_text: "Delete Lines"},
    {key: "deleteAreas", value: 3, display_text: "Delete Areas"},
];

export const AuditMapToolbar = ({
    AnnotationShapeIDMap,
    onSelectAnnotationShape,
    onAuditTypeChange,
    onAuditTypeItemChange,
    currentAnnotationShape,
    handleDeleteSelection,
    onResetAuditTypeItem,
    MapClicked,
    onDropDownReset,
    resetDeleteDropdown,
    annotationMarkers,
    annotationLines,
    annotationAreas,
    auditIssues,
    auditLines,
    auditAreas,
    auditsFilter,
    setAuditsFilter,
    auditsMapFilterRef,
}) => {
    const [auditTypes, setAuditTypes] = useState([]);
    const [currentAuditType, setCurrentAuditType] = useState(null);
    const [auditTypeItems, setAuditTypeItems] = useState([]);
    const [currentAuditTypeItem, setCurrentAuditTypeItem] = useState(null);
    const {auditsTypesItems, auditTypes: allTypes} = useSelector((state) => state.auditData);
    useEffect(() => {
        createSelectionOptions(allTypes, setAuditTypes);
    }, [allTypes]);

    useEffect(() => {
        if (currentAuditType && currentAnnotationShape?.query) {
            filterItems(currentAnnotationShape.query, currentAuditType.value);
        }
    }, [currentAuditType, currentAnnotationShape?.query]);

    useEffect(() => {
        if (currentAuditType) {
            onAuditTypeChange(currentAuditType);
        }
    }, [currentAuditType, onAuditTypeChange]);

    useEffect(() => {
        if (currentAuditTypeItem) {
            onAuditTypeItemChange(currentAuditTypeItem);
        }
    }, [currentAuditTypeItem, onAuditTypeItemChange]);

    useEffect(() => {
        if (MapClicked) {
            setCurrentAuditTypeItem(null);
            onResetAuditTypeItem();
        }
    }, [MapClicked]);

    const filterItems = (annotationType, auditTypeId) => {
        const type = annotationType === "item" ? "issue" : annotationType;
        const filtered = auditsTypesItems.filter((item) =>
            item.annotation_type === type && item.audit_type.id === auditTypeId).map((item) => ({
                key: item.id,
                value: item.id,
                display_text: item.name,
            }));
        setAuditTypeItems(filtered);
    };

    return (
        <ToolbarContainer>
            <div className="row py-3 align-items-center" id="map-info">
                <div className="col-lg-2 col-12 mb-4 mb-lg-0">
                    <h1 className="subtitle-md my-0">
                        Let's Go!
                    </h1>
                </div>

                <div className="col-lg-6 col-12">
                    <div className="inputsGroup px-3 d-flex justify-content-between align-items-center flex-lg-row flex-column me-lg-2 me-0 mb-2">
                        <div className="w-100 d-flex justify-content-between align-items-center mb-4 mb-lg-0 me-lg-3">
                            {auditTypes &&
                                <GSPSControlledLabelledSelectField
                                    inputID={"audit_type"}
                                    labelName="1- Audit Type"
                                    selectableOptions={auditTypes}
                                    value={currentAuditType?.value ?? ""}
                                    onChangeCB={(value) => {
                                        const selectedOption = auditTypes.find(type => type.value == value);
                                        setCurrentAuditType(selectedOption || null);
                                    }}
                                />}
                        </div>
                        <div className="w-100  d-flex justify-content-between align-items-center mb-4 mb-lg-0 me-lg-3">
                            <GSPSControlledLabelledSelectField
                                inputID={"annotation_shape"}
                                labelName={"2- Item Type"}
                                selectableOptions={
                                    Object.values(AnnotationShapeIDMap)?.map((obj) => ({
                                        key: obj.id,
                                        value: obj.id,
                                        display_text: obj.query,
                                    }))
                                }
                                value={currentAnnotationShape?.id ?? ""}
                                onChangeCB={(value) => {
                                    onSelectAnnotationShape(Number(value));
                                }}
                            />
                        </div>
                        <div className="w-100 d-flex justify-content-between align-items-center mb-4 mb-lg-0">{auditTypeItems &&
                            <GSPSControlledLabelledSelectField
                                inputID={"audit_type_item"}
                                labelName="3- Audit Item"
                                selectableOptions={auditTypeItems}
                                value={currentAuditTypeItem?.value ?? ""}
                                onChangeCB={(value) => {
                                    const selectedItem = auditTypeItems.find(type => type.value == value);
                                    setCurrentAuditTypeItem(selectedItem);
                                }}
                                disableAutoSorting
                            />
                        }
                        </div>
                    </div>
                </div>

                <div className="col-lg-4 col-12">
                    <div className="inputsGroup px-3 d-flex justify-content-between align-items-center flex-lg-row flex-column mb-2">
                        <div className="w-100 d-flex justify-content-between align-items-center mb-4 mb-lg-0">
                            <GSPSMapFilter
                                annotationMarkers={annotationMarkers}
                                annotationLines={annotationLines}
                                annotationAreas={annotationAreas}
                                auditIssues={auditIssues}
                                auditLines={auditLines}
                                auditAreas={auditAreas}
                                auditsFilter={auditsFilter}
                                setAuditsFilter={setAuditsFilter}
                                ref={auditsMapFilterRef}
                            />
                        </div>
                        <div className="w-100 d-flex justify-content-between align-items-center">
                            <GSPSToolbarSelectField
                                inputID="delete_actions"
                                selectableOptions={AuditDeletionOptionsList}
                                isRequired={true}
                                onChangeCB={(value) =>
                                    handleDeleteSelection(Object.values(AuditDeletionOptionsList).filter((obj) => obj.value == value)[0].key)
                                }
                                renderedElementType={"select"}
                                labelName="Delete"
                                DropDownReset={resetDeleteDropdown}
                            />
                            <Button type={"button"} variant={"contained"} className="px-3 d-none" startIcon={<CheckBoxOutlinedIcon />} onClick={(e) => {
                                e.preventDefault();
                            }}>
                                Finish
                            </Button>
                        </div>
                    </div>
                </div>

            </div>
        </ToolbarContainer>
    );
};

AuditMapToolbar.propTypes = {
    AnnotationShapeIDMap: PropTypes.object.isRequired,
    onSelectAnnotationShape: PropTypes.func.isRequired,
    currentAnnotationShape: PropTypes.object,
    onAuditTypeChange: PropTypes.func.isRequired,
    onAuditTypeItemChange: PropTypes.func.isRequired,
    onResetAuditTypeItem: PropTypes.func.isRequired,
    handleDeleteSelection: PropTypes.func,
    MapClicked: PropTypes.bool,
    resetDeleteDropdown: PropTypes.bool,
    onDropDownReset: PropTypes.func,
    annotationMarkers: PropTypes.array,
    annotationLines: PropTypes.array,
    annotationAreas: PropTypes.array,
    auditIssues: PropTypes.array,
    auditLines: PropTypes.array,
    auditAreas: PropTypes.array,
    auditsFilter: PropTypes.object,
    setAuditsFilter: PropTypes.func,
    auditsMapFilterRef: PropTypes.object,
};
