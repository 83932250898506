import styled from "styled-components";

export const StyledMediaContainer = styled.div`
    display: flex;
    gap: 40px;
    flex-wrap: wrap;
    margin: 20px 0;
`

export const StyledMediaInput = styled.span`
    margin-left : 10px;
    
    & .uploadIcon svg{
        fill: #02A783;
        color: white;
    }
`

