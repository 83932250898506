import {Toolbar, Typography} from "@mui/material"
import {DataGrid} from "@mui/x-data-grid";
import {useTheme} from "styled-components"
import GSPSDataTablePagination from "./DataTablePagination";
import PropTypes from "prop-types";


const GSPSPropDrivenTable = ({
    data = [],
    columns = [],
    uniqueTableID,
    rowHeight,
    title = "",
    tableAdminBtn = null,
    loading = false,
    disableColumnResize,
    disableColumnMenu,
    checkboxSelection,
    pagination = false
}) => {
    const theme = useTheme();

    return <div className="GSPSPropDrivenTable">
        <Toolbar
            className="px-3"
            sx={{
                backgroundColor: theme.color.secondaryBg,
                border: `2px solid ${theme.color.border}`,
                borderTopLeftRadius: "6px",
                borderTopRightRadius: "6px"
            }}
        >
            <Typography sx={{flex: "1 1 100%"}} variant="h3" id="tableTitle" component="h3" className="subtitle-lg">
                {title}
            </Typography>
            <div className="tableAdminBtn">{tableAdminBtn}</div>
        </Toolbar>
        <DataGrid
            rows={data}
            columns={columns}
            getRowId={uniqueTableID}
            getRowHeight={() => rowHeight}
            disableRowSelectionOnClick
            disableColumnFilter={disableColumnMenu}
            disableColumnMenu={disableColumnMenu}
            disableColumnResize={disableColumnResize}
            checkboxSelection={checkboxSelection}
            loading={loading}
            autoHeight
            slotProps={{
                loadingOverlay: {
                    variant: 'skeleton',
                    noRowsVariant: 'skeleton',
                }
            }}

            hideFooter={!pagination}
            pagination={pagination}
            pageSizeOptions={[5, 10, 25, 50, 100]}
            slots={{
                pagination: GSPSDataTablePagination,
            }}
            initialState={pagination && {
                pagination: {
                    paginationModel: {
                        pageSize: 10,
                    },
                },
            }}
            getCellClassName={() => 'body-md'}
            sx={{
                "border": `2px solid ${theme.color.border}`,
                "& .MuiDataGrid-columnHeaderTitleContainer": {
                    fontSize: "16px"
                },
                "& .MuiDataGrid-row": {
                    minHeight: "52px !important",
                    maxHeight: "300px !important"
                },
                "& .css-yrdy0g-MuiDataGrid-columnHeaderRow": {
                    backgroundColor: `${theme.color.secondaryBg} !important`,
                },
                "& .MuiTablePagination-displayedRows": {
                    marginBottom: "0px !important",
                },
                "& .MuiTablePagination-selectLabel": {
                    margin: "0",
                },
                "& .MuiDataGrid-cell--textLeft a": {
                    color: "#4CA2A8 !important",
                    fontSize: "14px",
                    fontWeight: "400",
                    textTransform: "none",
                },
                "& .MuiDataGrid-cell": {
                    textTransform: "capitalize",
                },
            }}
        />
    </div>
}

GSPSPropDrivenTable.propTypes = {
    columns: PropTypes.arrayOf(PropTypes.object).isRequired,
    data: PropTypes.array.isRequired,
    title: PropTypes.string,
    uniqueTableID: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
    ]).isRequired,
    rowHeight: PropTypes.string,
    tableAdminBtn: PropTypes.node,
    loading: PropTypes.bool,
    disableColumnResize: PropTypes.bool,
    disableColumnMenu: PropTypes.bool,
    checkboxSelection: PropTypes.bool,
    pagination: PropTypes.bool,
};

export default GSPSPropDrivenTable