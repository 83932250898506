import {API_ACCOUNTS} from "Models/Account/AccountEndpoints";
import {API_AUDITS} from "Models/Audit/AuditEndpoints";
import {API_LOCATIONS} from "Models/Location/LocationEndpoints";
import {API_USERS} from "Services/Endpoints";

export const auditRequests = [
    {key: "severities", endpoint: API_AUDITS.issuesSeverities + "?no_page"},
    {key: "questions", endpoint: API_AUDITS.questions},
    {key: "auditTypes", endpoint: API_AUDITS.auditsTypes + "?no_page"},
    {key: "auditsTypesItems", endpoint: API_AUDITS.auditsTypesItems},
    {key: "auditStatuses", endpoint: API_AUDITS.auditStatuses + "?no_page"},
    {key: "users", endpoint: API_USERS.users + "?no_page"},
    {key: "statusTypes", endpoint: API_LOCATIONS.statusTypes + "?no_page"},
    {key: "accountsDropdown", endpoint: API_ACCOUNTS.accountsDropdown + "?no_page"},
];
