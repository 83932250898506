import React, {useEffect, useRef, useState} from "react";
import {downloadPdf, makeGetRequest, previewPdf, printPage} from "Services/ServicesUtils";
import {API_AUDITS} from "Models/Audit/AuditEndpoints";
import {GSPSGoogleMap} from "Components/Map/GSPSGoogleMap";
import {useParams, useSearchParams} from "react-router-dom";
import {AuditMapSelectedIssues} from "Pages/Audits/AuditMapSelectedIssues";
import {AuditMapSelectedLines} from "Pages/Audits/AuditMapSelectedLines";
import {AuditMapSelectedAreas} from "Pages/Audits/AuditMapSelectedAreas";
import {EmailPopup} from "Components/Common/EmailPopup";
import {ReviewerInformation} from "Pages/Audits/AuditReviewer";
import {AuditQuestions} from "Pages/Audits/AuditQuestions";
import {initMediaSelector} from "./AuditMedia";
import {AuditorInformation} from "./AuditorInformation";
import GSPSAnnotationModal from "Components/Map/GSPSAnnotationModal";
import GSPSBreadCrumbs from "Components/Common/GSPSBreadCrumbs";
import {Divider} from "@mui/material";
import {AuditContainer} from "./AuditCreator.styles";
import {filterAndIndexItems} from "Utils/helpers";
import GSPSExportButton from "Components/Common/GSPSExportButton";
import ReportDocument from "docs/ReportDocument";
import {toPng} from "html-to-image";
import {useSelector} from "react-redux";
import {GSPSMapFilter} from "Components/Map/GSPSMapFilter";
import {Box} from '@mui/material';

export const AuditViewer = ({publicAudit, hideEmailPopup, PublicRecord}) => {
    const {auditID} = useParams();
    const [audit, setAudit] = useState();
    const [searchParams] = useSearchParams();
    const [reviewer, setReviewer] = useState([]);
    const [QuesAns, setQuesAns] = useState([]);
    const [annotationMarkers, setAnnotationMarkers] = useState([]);
    const [annotationLines, setAnnotationLines] = useState([]);
    const [annotationAreas, setAnnotationAreas] = useState([]);
    const endpointToUseAudit = publicAudit || API_AUDITS.audits;
    const [currentLocation, setCurrenLocation] = useState(null);
    const [clickedItem, setClickedItem] = useState(null);
    const [modalItemType, setModalItemType] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const hideEmailPopupFromURL = searchParams.get("report") === "false";
    const [questions, setQuestions] = useState([]);
    const issuesSeveritiesWithColor = useSelector((state) => state.auditData.severities);
    const [zoomLevel, setZoomLevel] = useState(18);
    const [mapImage, setMapImage] = useState(null);
    const mapRef = useRef();
    const [auditsFilter, setAuditsFilter] = useState({
        "items": [],
        "severity": [],
        "lines": [],
        "areas": [],
        "default": true,
    });

    const captureMap = () => {
        if (mapRef.current === null) {
            return;
        }
        toPng(mapRef.current, {cacheBust: true})
            .then(dataUrl => {
                setMapImage(dataUrl);
            })
            .catch(err => {
                console.error('Failed to capture map as image', err);
            });
    };

    useEffect(() => {
        const timer = setTimeout(() => {
            captureMap();
        }, 3000);

        return () => clearTimeout(timer);
    }, [mapRef.current]);


    const exportOptions = [
        {
            onClick: () => {
                mapImage &&
                    previewPdf(<ReportDocument audit={audit} mapImage={mapImage} />);
            },
            text: "Preview PDF"
        },
        {
            onClick: () => {
                mapImage &&
                    downloadPdf(<ReportDocument audit={audit} mapImage={mapImage} />, "SiteVisionPro - Report.pdf");
            },
            text: "Download PDF"
        }
    ]

    useEffect(() => {
        makeGetRequest(`${endpointToUseAudit}${auditID}/`).then(
            (res) => {
                setAudit(res);
                setReviewer([res.reviewer]);
                setQuesAns(res.answers.map((ans) => ans.answer_text));
                setQuestions(res.answers.map((ans) => ans.question_text));
            },
        );
    }, []);

    useEffect(() => {
        if (audit) {
            if (audit.audit_issues) {
                const issues = audit.audit_issues.map((issue) => ({
                    ...issue, images: issue.issue_images.length > 0 ? issue.issue_images : [
                        initMediaSelector("issue", annotationMarkers.length, "0"),
                        initMediaSelector("issue", annotationMarkers.length, "1"),
                    ],
                    videos: issue.issue_videos.length > 0 ? issue.issue_videos :
                        [initMediaSelector("issue", annotationMarkers.length, "0")],
                }));
                setAnnotationMarkers(issues);
            }
            if (audit.audit_areas) {
                const areas = audit.audit_areas.map((area) => ({
                    ...area, images: area.area_images.length > 0 ? area.area_images : [
                        initMediaSelector("area", annotationAreas.length, "0"),
                        initMediaSelector("area", annotationAreas.length, "1"),
                    ],
                    videos: area.area_videos.length > 0 ? area.area_videos :
                        [initMediaSelector("area", annotationAreas.length, "0")],
                }));
                setAnnotationAreas(areas);
            }
            if (audit.audit_lines) {
                const lines = audit.audit_lines.map((line) => ({
                    ...line, images: line.line_images.length > 0 ? line.line_images : [
                        initMediaSelector("line", annotationLines.length, "0"),
                        initMediaSelector("line", annotationLines.length, "1"),
                    ],
                    videos: line.line_videos.length > 0 ? line.line_videos :
                        [initMediaSelector("line", annotationLines.length, "0")],
                }));
                setAnnotationLines(lines);
            }
        }
        if (audit?.location?.address) {
            setCurrenLocation({
                "lat": Number(audit?.location?.address.latitude),
                "lng": Number(audit?.location?.address.longitude),
            });
        }
    }, [audit]);

    const onLineClick = (line) => {
        setModalItemType("line");
        setClickedItem(line);
        setIsModalOpen(true);
    };

    const onAreaClick = (Area) => {
        setModalItemType("area");
        setClickedItem(Area);
        setIsModalOpen(true);
    };
    const onIssueClick = (issue) => {
        setModalItemType("issue");
        setClickedItem(issue);
        setIsModalOpen(true);
    };

    const tidySelectedIssues = filterAndIndexItems(annotationMarkers, "items", auditsFilter, true);
    const tidySelectedLines = filterAndIndexItems(annotationLines, "lines", auditsFilter);
    const tidySelectedAreas = filterAndIndexItems(annotationAreas, "areas", auditsFilter);

    return (
        <AuditContainer>
            {!(hideEmailPopup || hideEmailPopupFromURL) && <EmailPopup />}

            {audit &&
                <>
                    <GSPSBreadCrumbs staticCrumb={`Audit: ${audit.name}`} />
                    <div className="container">

                        <GSPSExportButton options={exportOptions} />
                        <Box sx={{ margin: '20px auto' }}>
                            <GSPSMapFilter
                                annotationMarkers={annotationMarkers}
                                annotationLines={annotationLines}
                                annotationAreas={annotationAreas}
                                auditIssues={audit?.audit_issues}
                                auditLines={audit?.audit_lines}
                                auditAreas={audit?.audit_areas}
                                auditsFilter={auditsFilter}
                                setAuditsFilter={setAuditsFilter}
                            />
                        </Box>
                        <div ref={mapRef}>
                            <GSPSGoogleMap
                                audit={audit}
                                currentLocation={currentLocation}
                                annotationAreas={annotationAreas}
                                annotationLines={annotationLines}
                                annotationMarkers={annotationMarkers}
                                auditsFilter={auditsFilter}
                                isViewOnly={true}
                                onLineClick={onLineClick}
                                onIssueClick={onIssueClick}
                                onAreaClick={onAreaClick}
                                zoomLevel={zoomLevel}
                            />
                        </div>
                        <GSPSAnnotationModal
                            isOpen={isModalOpen}
                            onClose={() => setIsModalOpen(false)}
                            audit={audit}
                            clickedItem={clickedItem}
                            isViewOnly={true}
                            type={modalItemType}
                            issuesSeverities={issuesSeveritiesWithColor}
                        />
                        {audit.audit_issues_count > 0 &&
                            <AuditMapSelectedIssues
                                audit={audit}
                                tidySelectedIssues={tidySelectedIssues}
                                isViewOnly={true}
                            />
                        }
                        {audit.audit_lines_count > 0 &&
                            <AuditMapSelectedLines
                                tidySelectedLines={tidySelectedLines}
                                isViewOnly={true}
                                updateElementImages={null}
                                updateElementVideos={null} />
                        }
                        {audit.audit_areas_count > 0 &&
                            <AuditMapSelectedAreas
                                tidySelectedAreas={tidySelectedAreas}
                                isViewOnly={true}
                                isGoogleData={false}
                                updateElementImages={[]}
                                updateElementVideos={[]} />
                        }
                        <AuditQuestions
                            questions={questions}
                            auditorAnswers={QuesAns}
                            isReadOnly={true}
                        />

                        {/* <Divider className="my-4" /> */}
                        {/* TODO: Re-add Divider component when re-add check boxes */}

                        <AuditorInformation
                            audit={audit}
                            isReadOnly={true} />
                        {reviewer.length > 0 &&
                            <div>
                                <Divider className="my-4" />

                                <ReviewerInformation
                                    audit={audit}
                                    users={reviewer}
                                    readonly={true}
                                    PublicRecord={PublicRecord}
                                />
                            </div>
                        }
                    </div>
                </>
            }
        </AuditContainer>
    );
};
