import React from "react";
import PropTypes from "prop-types";
import {GooglePlaces} from "Components/Common/GooglePlaces";

export const AddressForm = ({
    id,
    register,
    errors,
    caller,
    labelSize = 5,
    controlSize = 5,
    setValue,
}) => {
    return <>
        <GooglePlaces
            setValue={setValue}
            caller={caller}
            id={id}
            register={register}
            errors={errors}
            isRequired
        />
    </>;
};


AddressForm.propTypes = {
    id: PropTypes.string.isRequired,
    errors: PropTypes.object.isRequired,
    register: PropTypes.func.isRequired,
    caller: PropTypes.object,
    labelSize: PropTypes.number,
    controlSize: PropTypes.number,
};
