import {IconButton, ListItemIcon, ListItemText, Menu, MenuItem} from '@mui/material';
import PropTypes from 'prop-types';
import {useState} from 'react';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import {useNavigate} from 'react-router-dom';


const GSPSMenu = ({index, actions}) => {
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const navigate = useNavigate();

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleActionClick = (action) => {
        if (typeof action.handleClick === "function") action.handleClick();
        else navigate(action.handleClick)
        handleClose();
    };

    return (
        <>
            <IconButton
                aria-label="more"
                id="long-button"
                key={index}
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <MoreHorizIcon />
            </IconButton>
            <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
            >
                {actions && actions.map((action, i) => (
                    <MenuItem
                        key={i}
                        onClick={() => handleActionClick(action)}
                        className='px-2'
                        sx={{minWidth: '170px'}}
                        disabled={action.disabled}
                    >
                        <ListItemIcon>
                            {action.icon}
                        </ListItemIcon>
                        <ListItemText>{action.text}</ListItemText>
                    </MenuItem>
                ))}
            </Menu>
        </>
    );
};

GSPSMenu.propTypes = {
    index: PropTypes.number.isRequired,
    actions: PropTypes.arrayOf(PropTypes.shape({
        icon: PropTypes.any.isRequired,
        text: PropTypes.string.isRequired,
        handleClick: PropTypes.func.isRequired,
        disabled: PropTypes.bool
    })).isRequired,
};

export default GSPSMenu;