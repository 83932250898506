import React from "react";
import App from "App";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import { FlagProvider } from "@unleash/proxy-client-react";
import { SnackbarProvider } from "notistack";
import GSPSNotification from "Components/Notifications/GSPSNotification";
import { Provider } from "react-redux";
import { createRoot } from "react-dom/client";
import { store } from "app/store";

const config = {
    url: process.env.REACT_APP_UNLEASH_URL,
    clientKey: process.env.REACT_APP_UNLEASH_API_KEY,
    refreshInterval: 15,
    appName: process.env.REACT_APP_UNLEASH_APP_NAME,
};

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

root.render(
    <React.StrictMode>
        <FlagProvider config={config}>
            <SnackbarProvider
                maxSnack={3}
                Components={{
                    success: GSPSNotification,
                    error: GSPSNotification,
                }}
            >
                <Provider store={store}>
                    <App />
                </Provider>
            </SnackbarProvider>
        </FlagProvider>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
