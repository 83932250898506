import React from 'react';
import {Page, Text, View, Document, StyleSheet, Image} from '@react-pdf/renderer';
import * as TimeZonesUtils from "Services/TimeZonesUtils";

// Define styles for the PDF
const styles = StyleSheet.create({
    page: {
        padding: 30,
        fontSize: 11
    },
    nav: {
        borderBottom: "3px solid gray",
        paddingBottom: "5px",
        marginBottom: 20,
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
    },
    navImg: {
        maxWidth: "100%",
        maxHeight: "100%",
        objectFit: "contain",
    },
    navImageContainer: {
        width: "100px",
        height: "60px",
        display: "flex",
        justifyContent: "start",
        alignItems: "center",
        overflow: "hidden"
    },
    section: {
        marginBottom: 10,
        marginTop: 10,
    },
    row: {
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    flex: {
        display: "flex",
    },
    flexColumn: {
        display: "flex",
        flexDirection: "column"
    },
    text: {
        fontSize: 11,
        marginBottom: 5,
        paddingBottom: 5
    },
    headline: {
        fontSize: 14,
        marginBottom: 10,
        fontWeight: 700
    },
    table: {
        border: "2px solid #dbe6e2",
        borderRadius: "10px",
        padding: "10px",
        marginBottom: "10px"
    },
    titleWithBorder: {
        borderBottom: "2px solid #dbe6e2",
        marginVertical: 5,
        paddingBottom: 5
    },
    imageContainer: {
        width: "100%",
        height: "200px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        overflow: "hidden"
    },
    tableImg: {
        maxWidth: "100%",
        maxHeight: "100%",
        objectFit: "contain",
        marginHorizontal: 20
    },
});

const ReportNav = ({audit}) => {
    return <View style={styles.nav}>
        <View>
            {
                audit.account.logo && <View style={styles.navImageContainer}>
                    <Image src={audit.account.logo} style={styles.navImg} />
                </View>
            }
        </View>
        <View style={audit.account.logo ? {width: "75%"} : {width: "100%"}}>
            <Text style={[styles.headline, styles.flexColumn]}>
                Report summary: {audit.name.trim()} - {audit.account.name.trim()}
            </Text>
            <Text style={styles.headline}>{audit.location.address.full_address}</Text>
        </View>
    </View>
}

const renderTablesInChunks = (items, title, Nav, renderItem) => {
    const pages = [];
    for (let i = 0; i < items.length; i += 2) {
        const chunk = items.slice(i, i + 2);
        pages.push(
            <Page size="A4" style={styles.page} key={`${title}-${i}`}>
                <Nav />

                <Text style={styles.headline}>{title}</Text>
                <View style={styles.section}>
                    {chunk.map((item, index) => renderItem(item, i + index))}
                </View>
            </Page>
        );
    }
    return pages;
};

const Table = ({title, id, description, content}) => {
    return <View key={id} style={styles.table}>

        <Text style={[styles.titleWithBorder, styles.headline]}>{title}</Text>

        {description && <Text>{description}</Text>}

        <View style={{marginVertical: 5}}>
            {content}
        </View>
    </View>
}

// Audit Report Document Component
const AuditReportDocument = ({audit, mapImage}) => {
    const currentDate = new Date();

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <ReportNav audit={audit} />

                {mapImage && (
                    <View style={styles.section}>
                        <Text style={styles.headline}>Map</Text>
                        <Image src={mapImage} source={mapImage} />
                    </View>
                )}
            </Page>

            {/* Render Issues in chunks */}
            {renderTablesInChunks(audit.audit_issues, "Issues", () => <ReportNav audit={audit} />, (issue, index) => (
                <Table
                    title={`${index + 1}- ${issue.audit_type_item.name} | ${issue.severity_object.name || "N/A"}`}
                    description={issue.description}
                    content={issue.issue_images.length ? (
                        <View style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            flexDirection: "row",
                            marginTop: 5
                        }}>
                            {issue.issue_images.map((image, index) => (
                                <View style={styles.imageContainer} key={index}>
                                    <Image key={image.image_sequence} src={image.image_value} style={styles.tableImg} />
                                </View>
                            ))}
                        </View>
                    ) : <Text style={{paddingVertical: 10}}>This issue has no images</Text>}
                />
            ))}

            {/* Render Lines in chunks*/}
            {renderTablesInChunks(audit.audit_lines, "Lines", () => <ReportNav audit={audit} />, (line, index) => (
                <Table
                    title={`${index + 1}- ${line.audit_type_item.name}`}
                    description={line.description}
                    content={
                        line.line_images.length ? (
                            <View style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                                flexDirection: "row",
                                marginTop: 5
                            }}>
                                {line.line_images.map((image) => (
                                    <View style={styles.imageContainer}>
                                        <Image key={image.image_sequence} src={image.image_value} style={styles.tableImg} />
                                    </View>
                                ))}
                            </View>
                        ) : <Text style={{paddingVertical: 10}}>This line has no images</Text>
                    }
                />
            ))}

            {/* Render Areas in chunks */}
            {renderTablesInChunks(audit.audit_areas, "Areas", () => <ReportNav audit={audit} />, (area, index) => (
                <Table
                    title={`${index + 1}- ${area.audit_type_item.name}`}
                    description={area.description}
                    content={area.area_images.length ? (
                        <View style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                            flexDirection: "row",
                            marginTop: 5
                        }}>
                            {area.area_images.map((image) => (
                                <View style={styles.imageContainer}>
                                    <Image key={image.image_sequence} src={image.image_value} style={styles.tableImg} />
                                </View>
                            ))}
                        </View>
                    ) : <Text style={{paddingVertical: 10}}>This area has no images</Text>}
                />
            ))}

            <Page size="A4" style={styles.page}>
                <ReportNav audit={audit} />

                {/* start Auditor Additional Information  */}
                <View style={styles.table}>
                    <Text style={[styles.titleWithBorder, styles.headline]}>
                        Auditor Additional Information
                    </Text>
                    <View style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        flexDirection: "row",
                        marginVertical: 5
                    }}>
                        <Text>{audit.auditor_additional_notes || "There are no additional notes for this audit"}</Text>
                    </View>
                </View>
                {/* end Auditor Additional Information  */}

                {/* start Reviewer Additional Information  */}
                <View style={styles.table}>
                    <Text style={[styles.titleWithBorder, styles.headline]}>
                        Reviewer Additional Information
                    </Text>
                    <View style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        flexDirection: "row",
                        marginVertical: 5
                    }}>
                        <Text>{audit.reviewer_additional_notes || "There are no additional notes for this audit"}</Text>
                    </View>
                </View>
                {/* end Reviewer Additional Information  */}
            </Page>

            <Page size="A4" style={styles.page}>
                <ReportNav audit={audit} />
                {/* start reviewer info  */}
                <View style={styles.table}>
                    <Text style={[styles.titleWithBorder, styles.headline]}>
                        Reviewer Information
                    </Text>
                    <View style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        flexDirection: "row",
                        marginVertical: 5
                    }}>
                        <Text style={[{marginRight: 5}]}>Name:</Text>
                        <Text>{audit.reviewer.full_name}</Text>
                    </View>
                    <View style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        flexDirection: "row",
                        marginVertical: 5
                    }}>
                        <Text style={[{marginRight: 5}]}>Phone:</Text>
                        <Text>{audit.reviewer.mobile_phone || "N/A"}</Text>
                    </View>
                    <View style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        flexDirection: "row",
                        marginVertical: 5
                    }}>
                        <Text style={[{marginRight: 5}]}>Email:</Text>
                        <Text>{audit.reviewer.email || "N/A"}</Text>
                    </View>
                </View>
                {/* end reviewer info  */}

                {/* start location info  */}
                <View style={styles.table}>
                    <Text style={[styles.titleWithBorder, styles.headline]}>
                        Location Information
                    </Text>
                    <View style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        flexDirection: "row",
                        marginVertical: 5
                    }}>
                        <Text style={[{marginRight: 5}]}>Name:</Text>
                        <Text>{audit.location.location || "N/A"}</Text>
                    </View>
                    <View style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        flexDirection: "row",
                        marginVertical: 5
                    }}>
                        <Text style={[{marginRight: 5}]}>Address:</Text>
                        <Text>{audit.location.address.full_address || "N/A"}</Text>
                    </View>
                    <View style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        flexDirection: "row",
                        marginVertical: 5
                    }}>
                        <Text style={[{marginRight: 5}]}>Contact:</Text>
                        <Text>{audit.location.contact_name || "N/A"}</Text>
                    </View>
                    <View style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        flexDirection: "row",
                        marginVertical: 5
                    }}>
                        <Text style={[{marginRight: 5}]}>Audit Created:</Text>
                        <Text>{TimeZonesUtils.convertToEst((audit.created_at.toString()), true)}</Text>
                    </View>
                    <View style={{
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        flexDirection: "row",
                        marginVertical: 5
                    }}>
                        <Text style={[{marginRight: 5}]}>Report Generated:</Text>
                        <Text>{TimeZonesUtils.convertToEst((currentDate.toString()), true)}</Text>
                    </View>
                </View>
                {/* end location info  */}
            </Page>
        </Document>
    );
};

export default AuditReportDocument;
