import {GSPSToolbarSelectField} from "Components/Common/GSPSToolbarSelectField";
import {useState, useEffect, useImperativeHandle, forwardRef} from "react";

export const GSPSMapFilter = forwardRef(({
        annotationMarkers,
        annotationLines,
        annotationAreas,
        auditIssues,
        auditLines,
        auditAreas,
        auditsFilter,
        setAuditsFilter
        }, ref) => {
        const [auditFilterOptionsList, setAuditFilterOptionsList] = useState([]);

        useImperativeHandle(ref, () => ({
            callReplaceTypeItemFilter(prevTypeName, newTypeName, annotationType) {
                replaceTypeItemFilter(prevTypeName, newTypeName, annotationType);
            },
            callAddTypeToAuditsFilterIfNotExists(annotationType, itemTypeName){
                addTypeToAuditsFilterIfNotExists(annotationType, itemTypeName);
            },
            callDeleteTypeFromAuditsFilterIfExists(annotationType, itemTypeName){                
                deleteTypeFromAuditsFilterIfExists(annotationType, itemTypeName);
            },
            callResetAuditTypesOptions(prevTypeName, newTypeName, annotationType){
                resetAuditTypesOptions(prevTypeName, newTypeName, annotationType);
            },
          }));

        useEffect(() => {
                if (auditIssues) {
                    setAuditFilterOptionsList((prev) => {
                        const isTypesAdded = prev.some((option) => option.label === "Items");
                        const isSeveritiesAddded = prev.some((option) => option.label === "Severity");
                        const newAuditsFilter = [
                            ...prev,
                            ...(!isTypesAdded ? [{checked: true, label: "Items", children: extractAuditTypes(auditIssues)}] : []),
                            ...(!isSeveritiesAddded ? [{checked: true, label: "Severity", children: extractSeverities(auditIssues)}] : [])
                        ];
                        return newAuditsFilter;
                    });
                }
                if (auditLines) {
                    setAuditFilterOptionsList((prev) => {
                        const isAdded = prev.some((option) => option.label === "Lines");
                        return isAdded ? prev : [...prev, {checked: true, label: "Lines", children: extractAuditTypes(auditLines)}];
                    });
                }
                if (auditAreas) {
                    setAuditFilterOptionsList((prev) => {
                        const isAdded = prev.some((option) => option.label === "Areas");
                        return isAdded ? prev : [...prev, {checked: true, label: "Areas", children: extractAuditTypes(auditAreas)}];
                    });
                }
        }, [auditIssues, auditLines, auditAreas]);

    useEffect(() => {
        const updatedAuditsFilter = {
            items: getCheckedLabels(auditFilterOptionsList, "Items"),
            severity: getCheckedLabels(auditFilterOptionsList, "Severity"),
            lines: getCheckedLabels(auditFilterOptionsList, "Lines"),
            areas: getCheckedLabels(auditFilterOptionsList, "Areas"),
        };

        setAuditsFilter(updatedAuditsFilter);
    }, [auditFilterOptionsList]);

    const getCheckedLabels = (optionsList, label) => {
        const option = optionsList.find((opt) => opt.label.toLowerCase() === label.toLowerCase());
        return option ? option.children.filter((child) => child.checked).map((child) => child.label) : [];
    };

    const updateAuditFilterOptionsList = (auditFilterOptionsList, value) => {
        const updatedList = auditFilterOptionsList.map((option) => {
            const key = option.label.toLowerCase();
            const correspondingValue = value[key] ?? [];
            if (correspondingValue) {
                option.checked = correspondingValue?.length > 0;
                option.children = option.children.map((child) => ({
                    ...child,
                    checked: correspondingValue.includes(child.label),
                }));
            }
            return option;
        });
        return updatedList;
    };

    const extractAuditTypes = (items) => {
        const auditTypes = items
            .map((item) => ({label: item.audit_type_item.name?.trim(), checked: true}))
            .filter((obj, index, self) =>
                self.findIndex((element) => element.label === obj.label) === index,
            );
        return auditTypes;
    };
    const extractSeverities = (items) => {
        const severities = items
            .map((item) => ({label: item.severity_object.name?.trim(), checked: true}))
            .filter((obj, index, self) =>
                self.findIndex((element) => element.label === obj.label) === index,
            );
        return severities;
    };

    const addTypeToAuditsFilterIfNotExists = (annotationType, typeName) => {
        const auditTypes = getAnnotationAuditTypes(annotationType);
        if (auditTypes && !auditTypes.children.some((child) => child.label === typeName?.trim())) {
            setAuditFilterOptionsList((prevState) => {
                const updatedChildren = [...auditTypes.children, {label: typeName?.trim(), checked: true}];
                const updatedAuditFilterOptionsList = prevState.map((option) => {
                    if (option.label === auditTypes.label) {
                        return {...option, checked: true, children: updatedChildren};
                    }
                    return option;
                });                
                return updatedAuditFilterOptionsList;
            });
        }
    };

    const deleteTypeFromAuditsFilterIfExists = (annotationType, typeName) => {
        const auditTypes = getAnnotationAuditTypes(annotationType);
        const annotationMap = {
            Items: annotationMarkers,
            Severity: annotationMarkers,
            Lines: annotationLines,
            Areas: annotationAreas,
        };
        const annotationArray = annotationMap[auditTypes.label];
        
        const auditTypeOccurs = annotationArray?.filter(
            (item) => {
                if (auditTypes?.label === "Severity") {
                    return item.severity_object.name?.trim() === typeName.trim();
                }
                return item.audit_type_item.name?.trim() === typeName.trim();
            },
        )?.length;
        setAuditFilterOptionsList((prevState) => {
            return prevState.map((option) => {
                if (option.label !== auditTypes.label) return option;

                if (auditTypeOccurs === 1) {
                    const updatedChildren = option.children.filter(
                        (child) => child.label !== typeName,
                    );
                    return {...option, checked: true, children: updatedChildren};
                }

                return option;
            });
        });
    };

    const replaceTypeItemFilter = (prevTypeName, newTypeName, annotationType) => {        
        addTypeToAuditsFilterIfNotExists(annotationType, newTypeName);
        deleteTypeFromAuditsFilterIfExists(annotationType, prevTypeName?.trim());
    };

    const handleFilterSelection = (value) => {
        try {
            const updatedAuditFilterOptionsList = updateAuditFilterOptionsList(auditFilterOptionsList, value);
            setAuditFilterOptionsList(updatedAuditFilterOptionsList);
            setAuditsFilter(value);
        } catch (e) {
            insertNotification("Error", `can't filter. Reason: ${e.message}`, "error");
        }
    };

    const resetAuditTypesOptions = (annotationType) => {
        setAuditFilterOptionsList((currentAuditFilterOptionsList) => currentAuditFilterOptionsList.map((option) => {
            if (option.label === annotationType) {
                return {
                    ...option,
                    children: [],
                };
            }
            return {
                ...option,
                children: [...option.children],
            };
        }));
    };
    
    const getAnnotationAuditTypes = (annotationType) => {
        return auditFilterOptionsList.find((option) => option.label === annotationType);
    }

    return (
            <GSPSToolbarSelectField
                inputID="filter_audits"
                selectableOptions={auditFilterOptionsList}
                onChangeCB={(value) => {
                    handleFilterSelection(value);
                }}
                labelName="View"
                renderedElementType={"filter"}
                updatedAuditFilter={auditsFilter}
            />
    );
});