import React, {useContext, useEffect, useState} from "react";
import {GSPSSubmitButton} from "Components/Common/GSPSSubmitButton";
import {fetchSelectionOptions, makeGetRequest, makePostRequest, useIsDesktop} from "Services/ServicesUtils";
import {GSPSToolbarSelectField} from "Components/Common/GSPSToolbarSelectField";
import {ToolbarContainer} from "Pages/Audits/AuditMapToolbar.styles";
import {API_ACCOUNTS} from "Models/Account/AccountEndpoints";
import {API_LOCATIONS} from "Models/Location/LocationEndpoints";
import {DatePicker} from "@mui/x-date-pickers";
import {Backdrop, Button, CircularProgress, Toolbar, Typography} from "@mui/material";
import {insertNotification} from "Utils/NotificationsUtils";
import {DatePickerContainer} from "Components/Common/DatePicker.styles";
import GSPSBreadCrumbs from "Components/Common/GSPSBreadCrumbs";
import {DataGrid} from "@mui/x-data-grid";
import {useTheme} from "styled-components";
import GetAppIcon from '@mui/icons-material/GetApp';
import * as XLSX from "xlsx";
import GSPSPropDrivenTable from "Components/DataTable/GSPSPropDrivenTable";
import {UserContext} from "context/UserContext";
import { API_SUBSCRIBER } from "Models/Subscriber/SubscriberEndpoints";
import { API_AUDITS } from "Models/Audit/AuditEndpoints";
export const Report = () => {
    const {user: currentUser, permissions} = useContext(UserContext);
    const isStaff = permissions.isStaff;
    const [accounts, setAccounts] = useState([]);
    const [subscribers, setSubscribers] = useState();
    const [locations, setLocations] = useState([]);
    const [selectedAccount, setSelectedAccount] = useState(null);
    const [selectedLocation, setSelectedLocation] = useState(null);
    const [selectedSubscriber, setSelectedSubscriber] = useState();
    const [locationsReset, setLocationsReset] = useState(false);
    const [accountsReset, setAccountsReset] = useState(false);
    const [fromDate, setFromDate] = useState("2024-01-01");
    const [toDate, setToDate] = useState(new Date().toISOString().slice(0, 10)); // now date

    const [accountAreas, setAccountAreas] = useState([]);
    const [accountLines, setAccountLines] = useState([]);
    const [accountIssues, setAccountIssues] = useState([]);
    const [allData, setAllData] = useState(false);
    const [loading, setLoading] = useState(false);
    const [componentLoading, setComponentLoading] = useState(false);

    const isDesktop = useIsDesktop();
    const theme = useTheme();

    useEffect(()=>{
        if (currentUser) {
            setSelectedSubscriber({
                key: currentUser?.subscriber,
                value: currentUser?.subscriber,
                display_text: currentUser?.subscriber_name,
            });
        }
    }, [currentUser])

    const reportColumns = [
        {
            field: 'id',
            headerName: 'ID',
            ...(isDesktop ? {flex: 1} : {width: 200}),
        },
        {
            field: 'audit_name',
            headerName: 'Audit Name',
            ...(isDesktop ? {flex: 2} : {width: 200}),
        },
        {
            field: 'audit_address',
            headerName: 'Audit Address',
            ...(isDesktop ? {flex: 2} : {width: 300}),
        },
        {
            field: 'audit_type_item',
            headerName: 'Type',
            ...(isDesktop ? {flex: 2} : {width: 200}),
            valueGetter: (params) => params.name
        },
    ];

    const formatDate = (date) => {
        const options = {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
        };
        const formattedDate = new Date(date).toLocaleDateString("en-CA", options); // "en-CA" gives ISO format (YYYY-MM-DD)
        return formattedDate;
    };
    const adjustedFromDate = formatDate(fromDate);
    const adjustedToDate = formatDate(toDate);

    useEffect(() => {
        setComponentLoading(true);
        fetchSelectionOptions(API_ACCOUNTS.accountsDropdown, setAccounts);
        if (isStaff){
            fetchSelectionOptions(API_SUBSCRIBER.subscribers, setSubscribers);
        }
        setComponentLoading(false);
    }, [isStaff]);

    const resetLocationsDropdown = () => {
        setLocationsReset(true);
        setLocations([]);
        setSelectedLocation(null);
    };

    const resetAccountsDropdown = () => {
        setAccountsReset(true);
        setAccounts([]);
        setSelectedAccount(null);
    };

    useEffect(() => {
        if (selectedAccount) {
            try {
                setComponentLoading(true);
                if (selectedAccount.value !== "ALL") {
                    fetchSelectionOptions(API_LOCATIONS.locationsDropdown + `?account.id=${selectedAccount.value}`,
                        setLocations,
                        "full_address");
                    setLocationsReset(true);
                    setSelectedLocation(null);
                }
                else {
                    resetLocationsDropdown();
                }
            }
            finally {
                setComponentLoading(false);
            }
        }
    }, [selectedAccount]);

    const handleSubscriberSelection = (subscriber) => {
        setComponentLoading(true);
        setSelectedSubscriber(subscriber);
        resetAccountsDropdown();
        resetLocationsDropdown();
        fetchSelectionOptions(API_ACCOUNTS.accountsDropdown + `?subscriber.id=${subscriber.value}`, setAccounts);
        setComponentLoading(false);
    }

    const handleAccountSelection = (account) => {
        if (account && account.value != 0) {
            setSelectedAccount(account);
            setAccountsReset(false);
        } else {
            setSelectedAccount(null);
        }
    };

    const handleLocationSelection = (location) => {
        if (location && location.value != 0) {
            setLocationsReset(false);
            setSelectedLocation(location);
        } else {
            setSelectedLocation(null);
        }
    };

    const exportToExcel = (data) => {
        const rows = [];
        data.forEach((audit) => {
            [["audit_issues", "Issue"], ["audit_lines", "Line"], ["audit_areas", "Area"]].forEach(type => {
                audit[type[0]].forEach((item) => {
                    rows.push({
                        "Type": type[1],
                        "Audit Name": item.audit_name,
                        "Audit Type": item.audit_type_item.audit_type.name,
                        "Audit Type Item": item.audit_type_item.name,
                        "Location": item.audit_address,
                        "Account": audit.account_name,
                        "Account Admin": audit.account_admin,
                    });
                });
            });
        });
        const ws = XLSX.utils.json_to_sheet(rows);
        const defaultColWidth = 20;
        ws["!cols"] = Array(Object.keys(rows[0]).length).fill({wch: defaultColWidth});
        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
        XLSX.writeFile(wb, "table_data.xlsx");
    };

    async function handleSubmit() {
        if (selectedLocation === null) {
            insertNotification("Error", `Please select a location.`, "error");
            return;
        }
        setLoading(true);
        const data = {
            'location_ids': [selectedLocation?.value],
            'from_date': adjustedFromDate,
            'to_date': adjustedToDate,
        }
        makePostRequest(`${API_AUDITS.report}`, data)
            .then(
                (res) => {
                    console.log(res);
                    if (res.length === 0) {
                        insertNotification("Warning", "No data found for the selected criteria.", "warning");
                    }
                    setAccountAreas(res.map(item => item.audit_areas).flat());
                    setAccountLines(res.map(item => item.audit_lines).flat());
                    setAccountIssues(res.map(item => item.audit_issues).flat());
                    setAllData(res);
                })
            .catch((error) => {
                insertNotification("Error", `Please make sure you have selected a location.`, "error");
            }).finally(() => {
                setLoading(false);
            })
    };

    const renderToolbar = () => (
        <ToolbarContainer>
            <div className="row mb-3">
                {isStaff && subscribers && (
                    <div className="col-6 d-flex justify-content-between align-items-center my-3">
                        <GSPSToolbarSelectField
                            inputID={"subscriber"}
                            value={selectedSubscriber}
                            selectableOptions={subscribers}
                            labelName="Subscriber"
                            onChangeCB={handleSubscriberSelection}
                            variant="outlined"
                        />
                    </div>
                )}

                {accounts && (
                    <div className="col-6 d-flex justify-content-between align-items-center my-3">
                        <GSPSToolbarSelectField
                            defaultValue={{key: "DefaultValue", value: 0, display_text: "Account"}}
                            inputID={"account"}
                            DropDownReset={accountsReset}
                            selectableOptions={accounts}
                            labelName="Account"
                            onChangeCB={handleAccountSelection}
                            variant="outlined"
                        />
                    </div>
                )}

                {locations && locations.length > 0 && (
                    <div className="col-6 d-flex justify-content-between align-items-center my-3">
                        <GSPSToolbarSelectField
                            defaultValue={{key: "DefaultValue", value: "A&&", display_text: "Select"}}
                            DropDownReset={locationsReset}
                            inputID={"location"}
                            selectableOptions={locations}
                            labelName="Location"
                            onChangeCB={handleLocationSelection}
                            variant="outlined"
                        />
                    </div>
                )}

                <div className="col-4 d-flex justify-content-between align-items-center">
                    <DatePickerContainer>
                        <DatePicker
                            label="Start Date"
                            startDate={new Date("2024-01-01")}
                            minDate={new Date("2024-01-01")}
                            disableFuture
                            slotProps={{
                                textField: {
                                    helperText: 'MM/DD/YYYY',
                                },
                            }}
                            onChange={(date) => setFromDate(date)}
                        />
                    </DatePickerContainer>
                </div>

                <div className="col-4 d-flex justify-content-between align-items-center">
                    <DatePickerContainer>
                        <DatePicker
                            label="End Date"
                            startDate={fromDate}
                            disableFuture
                            minDate={fromDate}
                            slotProps={{
                                textField: {
                                    helperText: 'MM/DD/YYYY',
                                },
                            }}
                            onChange={(date) => setToDate(date)}
                        />
                    </DatePickerContainer>
                </div>

                <div className="col-4 d-flex justify-content-end align-items-center">
                    <GSPSSubmitButton
                        isDisabled={false}
                        controlSize={2}
                        offset={0}
                        buttonText="Submit"
                        onClick={handleSubmit}
                        colClassName="w-25 mb-3"
                    />
                </div>
            </div>
        </ToolbarContainer>
    );

    const renderTable = (title, data) => {
        console.log(data);
        return (
            <div className="my-5">
            <GSPSPropDrivenTable
                title={title}
                data={data}
                columns={reportColumns}
                loading={loading}
                disableColumnResize
                disableColumnMenu
            />
        </div>
        )};

    const renderTables = () => (
        <>
            <div className="d-flex justify-content-end align-items-center">
                <Button
                    variant="outlined"
                    className="mt-4 cta-btn"
                    endIcon={<GetAppIcon />}
                    onClick={() => exportToExcel(allData)}
                    sx={{
                        color: theme.color.dark,
                        borderColor: theme.color.dark,
                        textTransform: "capitalize",
                        ":hover": {
                            borderColor: theme.color.info,
                        },
                    }}
                >
                    Export
                </Button>
            </div>

            {renderTable("Issues", accountIssues)}
            {renderTable("Lines", accountLines)}
            {renderTable("Areas", accountAreas)}
        </>
    );

    return (
        <>
            <div>
                <GSPSBreadCrumbs />
                    <div className="container">
                        {componentLoading? <CircularProgress color="inherit" /> : renderToolbar()}
                        {allData.length > 0 && renderTables()}
                    </div>
            </div>
            <Backdrop sx={{color: '#fff', zIndex: "555"}} open={loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
        </>
    );
};
