import {pdf} from "@react-pdf/renderer";
import jwtDecode from "jwt-decode";
import React, {useEffect, useState} from "react";
import {API_USERS} from "Services/Endpoints";
import {insertNotification} from "Utils/NotificationsUtils";

export class HttpError extends Error {
    constructor(status, body) {
        super(`HTTP Error: ${status}`);
        this.status = status;
        this.body = body;
    }
}

const HttpCode = {
    OK: 200,
    CREATED: 201,
    ACCEPTED: 202,
    UNAUTHORIZED: 401,
    INVALID: 400,
    NoContent: 204,
    FORBIDDEN: 403,
    BAD_GATEWAY: 502,
};

export const HttpErrorMessage = {
    [HttpCode.INVALID]: "Invalid Request",
    [HttpCode.UNAUTHORIZED]: "Unauthorized action",
    DEFAULT_ERROR_MESSAGE: "HTTP status code not recognised",
};

export function makeGetRequest(url) {
    return fetch(url, getHTTPGetConf())
        .then(handleServerResponse())
        .catch((error) => {
            if (error.message === String(HttpCode.UNAUTHORIZED)) {
                return refreshToken().then(() => makeGetRequest(url));
            }
            throw error;
        },
        );
}

// Explicitly for POST requests that does not require authentication to perform
export async function makeUnauthenticatedPostRequest(url, data) {
    return fetch(url, getHTTPPostConf(data, false))
        .then(async (response) => {
            if (!response || response.status != HttpCode.OK) {
                throw new HttpError(response.status, await response.json());
            }
            return await response.json();
        });
}
export function makePostRequest(url, data) {
    return fetch(url, getHTTPPostConf(data, true))
        .then(handleServerResponse())
        .catch((error) => {
            if (error.message === String(HttpCode.UNAUTHORIZED)) {
                return refreshToken().then(() => makePostRequest(url, data));
            }
            throw error;
        });
}


export function makeDeleteRequest(url, data) {
    return fetch(url, getHTTPDeleteConf(data))
        .then(handleServerResponse())
        .catch((error) => {
            if (error.message === String(HttpCode.UNAUTHORIZED)) {
                return refreshToken().then(() => makeDeleteRequest(url, data));
            }
            throw error;
        });
}

export function makeUpdateRequest(url, data) {
    return fetch(url, getHTTPUpdateConf(data))
        .then(handleServerResponse())
        .catch((error) => {
            if (error.message === String(HttpCode.UNAUTHORIZED)) {
                return refreshToken().then(() => makeUpdateRequest(url, data));
            }
            throw error;
        },
        );
}

export function makePatchRequest(url, data) {
    return fetch(url, getHTTPPatchConf(data))
        .then(handleServerResponse())
        .catch((error) => {
            if (error.message === String(HttpCode.UNAUTHORIZED)) {
                return refreshToken().then(() => makePatchRequest(url, data));
            }
            throw error;
        },
        );
}

export function makeFormDataUpdateRequest(url, data) {
    return fetch(url, getHTTPFormDataUpdateConf(data))
        .then(handleServerResponse())
        .catch((error) => {
            if (error.message === String(HttpCode.UNAUTHORIZED)) {
                return refreshToken().then(() => makeFormDataUpdateRequest(url, data));
            }
            throw error;
        },
        );
}

export function makeFormDataPostRequest(url, data) {
    return fetch(url, getHTTPFormDataPostConf(data))
        .then(handleServerResponse())
        .catch((error) => {
            if (error.message === String(HttpCode.UNAUTHORIZED)) {
                return refreshToken().then(() => makeFormDataPostRequest(url, data));
            }
            throw error;
        });
}

function handleServerResponse() {
    return async (response) => {
        switch (response.status) {
            case HttpCode.OK:
            case HttpCode.CREATED:
            case HttpCode.ACCEPTED:
                return response.json();
            case HttpCode.UNAUTHORIZED:
                throw new Error(HttpCode.UNAUTHORIZED);
            case HttpCode.INVALID:
                throw response.json();
            case HttpCode.FORBIDDEN:
                throw response.json();
            case HttpCode.NoContent:
                return response;
            case HttpCode.BAD_GATEWAY:
                throw new Error("Bad Gateway");
            default:
                throw new Error(HttpErrorMessage.DEFAULT_ERROR_MESSAGE);
        }
    };
}


const isValidRefreshToken = () => {
    const token = localStorage.getItem("refresh_token");
    if (!token || token === "undefined") {
        return false;
    }
    const currentTime = new Date().getTime();
    const decoded = jwtDecode(token);
    return !((currentTime / 1000) > decoded["exp"]);
};
export const refreshToken = async () => {
    const refreshToken = localStorage.getItem("refresh_token");

    if (!isValidRefreshToken()) {
        localStorage.clear();
        insertNotification("info", "Session expired, You should login again!", "info");
        window.location.href = "/login";
        return;
    }
    return makePostRequest(API_USERS.refreshToken, {"refresh": refreshToken})
        .then((jsonRes) => {
            const newAccessToken = jsonRes.access;
            localStorage.setItem("access_token", newAccessToken);
            return newAccessToken;
        })
        .catch((error) => {
            throw error;
        });
};
function getHTTPGetConf() {
    const token = localStorage.getItem("access_token");
    return {
        method: "GET",
        headers: new Headers({
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
        }),
    };
}

function getHTTPDeleteConf(data) {
    const token = localStorage.getItem("access_token");

    return {
        method: "DELETE",
        headers: new Headers({
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
        }),
        body: JSON.stringify(data),
    };
}

function getHTTPPostConf(data, authorizationRequired = true) {
    const token = localStorage.getItem("access_token");

    const requestHeader = new Headers({
        "Content-Type": "application/json",
    });

    if (authorizationRequired && token) {
        requestHeader.append("Authorization", `Bearer ${token}`);
    }

    return {
        method: "POST",
        headers: requestHeader,
        body: JSON.stringify(data),
    };
}

function getHTTPFormDataUpdateConf(data) {
    const token = localStorage.getItem("access_token");

    return {
        method: "PUT",
        // Do Not explicitly set the content-type according to:
        // https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest_API/Using_FormData_Objects
        headers: new Headers({
            "Authorization": `Bearer ${token}`,
        }),
        body: data,
    };
}

function getHTTPFormDataPostConf(data) {
    const token = localStorage.getItem("access_token");

    return {
        method: "POST",
        // Do Not explicitly set the content-type according to:
        // https://developer.mozilla.org/en-US/docs/Web/API/XMLHttpRequest_API/Using_FormData_Objects
        headers: new Headers({
            "Authorization": `Bearer ${token}`,
        }),
        body: data,
    };
}

function getHTTPUpdateConf(data) {
    const token = localStorage.getItem("access_token");

    return {
        method: "PUT",
        headers: new Headers({
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
        }),
        body: JSON.stringify(data),
    };
}

function getHTTPPatchConf(data) {
    const token = localStorage.getItem("access_token");

    return {
        method: "PATCH",
        headers: new Headers({
            "Content-Type": "application/json",
            "Authorization": `Bearer ${token}`,
        }),
        body: JSON.stringify(data),
    };
}

export function useIsMounted() {
    // https://github.com/webNeat/react-tidy/tree/master/src/useIsMounted
    const ref = React.useRef(true);
    React.useEffect(() => {
        return () => {
            ref.current = false;
        };
    }, []);
    return React.useCallback(() => ref.current, []);
}


export const fetchSelectionOptions = (url, setSelection, nameField = "name", no_page = true) => {
    makeGetRequest(
        (url.includes("?") && no_page) ?
            `${url}&no_page` : no_page ?
                `${url}?no_page` : url,
    ).then((data) => {
        const activeItems = (no_page ? data : data.results).filter((serviceType) => serviceType.is_active !== false);
        setSelection(activeItems.map((serviceType) => {
            const displayText = nameField.split(".").reduce((obj, key) => obj && obj[key], serviceType);
            return {
                key: serviceType.id,
                value: serviceType.id,
                display_text: displayText,
            };
        }));
    }).catch((error) => {
        insertNotification("Errors", "Error getting selections " + JSON.stringify(error), "error");
    });
};
export const createSelectionOptions = (data = [], setSelection, nameField = "name", no_page = true) => {
    const activeItems = (no_page ? data : data.results).filter((item) => item.is_active !== false);
    const options = activeItems.map((item) => {
        const displayText = nameField.split(".").reduce((obj, key) => obj && obj[key], item);
        return {
            key: item.id,
            value: item.id,
            display_text: displayText,
        };
    });
    setSelection(options);
};

export const fetchQuestions = (url, textField = "question_text", idField = "id") => {
    return new Promise((resolve, reject) => {
        makeGetRequest(url)
            .then((data) => {
                const text = data.results.map((item) => item[textField]);
                const id = data.results.map((item) => item[idField]);

                resolve({text: text, id: id});
            })
            .catch((error) => {
                reject(`Error getting questions: ${error}`);
            });
    });
};

export const useIsDesktop = () => {
    const [isDesktop, setIsDesktop] = useState(window.innerWidth >= 1024);

    useEffect(() => {
        const handleResize = () => {
            setIsDesktop(window.innerWidth >= 1024);
        };

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return isDesktop;
};

export const humanizeText = (fieldName) => {
    let formattedName = fieldName.replace(/[_]/g, " ");
    formattedName = formattedName.replace(/\b\w/g, (char) => char.toUpperCase());
    return formattedName;
};

export const printPage = () => {
    window.print();
}

export const downloadPdf = async (doc, title) => {
    const blob = await pdf(doc).toBlob();
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = title;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export const previewPdf = async (doc) => {
    const blob = await pdf(doc).toBlob(); // Convert the document to a blob
    const url = URL.createObjectURL(blob); // Create a URL for the blob
    window.open(url); // Open the PDF in a new tab for preview
};

