import React, {useState} from "react";
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import jwtDecode from "jwt-decode";
import {Container} from "react-bootstrap";
import {getPathElementMap, getUnauthenticatedPathElementMap, unRedirectionPaths} from "AppRoutes";
import {TopMenu} from "Pages/TopMenu/TopMenu";
import {ReactNotifications} from "react-notifications-component";
import "react-notifications-component/dist/theme.css";
import {AppStyle} from "Styles/Pages.styles";
import theme, {AvailableThemes} from "theme";
import {ThemeProvider} from "styled-components";
import {LoadScript} from "@react-google-maps/api";
import {useFlag} from "@unleash/proxy-client-react";
import GSPSFooter from "Components/Common/GSPSFooter";
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFnsV3";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {makePostRequest} from "Services/ServicesUtils";
import {API_USERS} from "Services/Endpoints";
import {UserProvider} from "context/UserContext";

const App = () => {
    const isValidRefreshToken = () => {
        const token = localStorage.getItem("refresh_token");

        if (!token || token === "undefined") {
            return false;
        }
        const currentTime = new Date().getTime();
        const decoded = jwtDecode(token);
        return !((currentTime / 1000) > decoded["exp"]);
    };

    // I intentionally left this code here until we figure
    // out where this will go
    // const switchTheme = () => {
    //     if (currentTheme === AvailableThemes.light) {
    //         setCurrentTheme(AvailableThemes.dark);
    //     } else {
    //         setCurrentTheme(AvailableThemes.light);
    //     }
    // };

    const [hasValidRefreshToken, setHasValidRefreshToken] = useState(Boolean(isValidRefreshToken()));
    const [currentTheme] = useState(AvailableThemes.light);
    const isCopyRightEnabled = useFlag("Copyright");

    const authenticatedPathElementMap = getPathElementMap(resetCurrentUserSession);
    const unauthenticatedPathElementMap = getUnauthenticatedPathElementMap(setHasValidRefreshToken);

    function resetCurrentUserSession(navigate) {
        makePostRequest(API_USERS.logout)
            .then(() => {
                localStorage.clear();
                setHasValidRefreshToken(false);
                navigate("/");
            });
    }

    return (
        <>
            <ReactNotifications />
            <ThemeProvider theme={theme[currentTheme]}>
                <AppStyle>
                    <div className="App">
                        <Container fluid className="p-0">
                            <BrowserRouter>
                                {hasValidRefreshToken &&
                                    <Routes>
                                        {authenticatedPathElementMap.map((data) => {
                                            return (
                                                <Route
                                                    key={data.path}
                                                    path={data.path}
                                                    element={
                                                        <>
                                                            <UserProvider>
                                                                <LoadScript
                                                                    googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
                                                                    libraries={["places", "drawing", "geometry"]}
                                                                >

                                                                    <LocalizationProvider dateAdapter={AdapterDateFns}>

                                                                        <TopMenu
                                                                            resetCurrentUserSession={resetCurrentUserSession}
                                                                        />
                                                                        {data.element}
                                                                        <GSPSFooter />

                                                                    </LocalizationProvider>
                                                                    {isCopyRightEnabled && <h4>Feature Flag Footer Test</h4>}
                                                                </LoadScript>
                                                            </UserProvider>
                                                        </>
                                                    }
                                                />
                                            );
                                        })};
                                    </Routes>
                                }
                                <Routes>
                                    {unauthenticatedPathElementMap.map((data) => {
                                        if (hasValidRefreshToken && location.pathname.startsWith("/public-audits/")) return null;
                                        return (
                                            <Route
                                                key={data.path}
                                                path={data.path}
                                                element={
                                                    hasValidRefreshToken ?
                                                        <>
                                                            {!["*", "/"].includes(data.path) && !unRedirectionPaths.includes(data.path) &&
                                                                <Navigate to={{pathname: "/dashboard"}} />
                                                            }
                                                        </> :
                                                        <>
                                                            <LoadScript
                                                                googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAP_KEY}
                                                                libraries={["places", "drawing", "geometry"]}
                                                            >
                                                                {data.element}
                                                            </LoadScript>
                                                        </>
                                                }
                                            />
                                        );
                                    })};
                                </Routes>
                            </BrowserRouter>
                            {/* <div className="form-check form-switch">
                    <input className="form-check-input"
                        type="checkbox"
                        role="switch"
                        id="flexSwitchCheckDefault"
                        onClick={() => switchTheme()}>
                    </input>
                </div> */}
                        </Container>
                    </div>
                </AppStyle>
            </ThemeProvider>
        </>
    );
};

export default App;
