import {useNavigate} from "react-router-dom";
import React, {useContext, useEffect, useState} from "react";
import {makeGetRequest} from "Services/ServicesUtils";
import {API_USERS} from "Services/Endpoints";
import {insertNotification} from "Utils/NotificationsUtils";
import {UserFormFields} from "Pages/Users/UserFormFields";
import {GSPSPageWrapper, GSPSPopUpHeader} from "Components/Common/GSPSStyledContainers.styles";
import {useUpdateUserMutation} from "api/UserTableAPI";
import PropTypes from "prop-types";
import {UserContext} from "context/UserContext";

export const UserEditor = ({show, setShow, userID}) => {
    const [user, setUser] = useState();
    const navigate = useNavigate();
    const [updateUser] = useUpdateUserMutation();
    const {permissions} = useContext(UserContext);
    const isAdmin = permissions.isAdmin;

    useEffect(() => {
        makeGetRequest(`${API_USERS.users}${userID}/`)
            .then(
                (res) => {
                    setUser(res);
                },
            );
    }, []);

    const onSubmit = async (data) => {
        const updateData = {
            ...data,
            role_id: data.user_title,
            group_permission_id: data.user_group_permission,
            industry_type_id: data.user_industry_type,
        };

        try {
            const updatedUser = await updateUser({id: userID, updatedItem: {...updateData}}).unwrap();
            insertNotification("Success", `User ${updatedUser.full_name} has been updated.`, "success");
            if (isAdmin) {
                setShow(false);
            } else {
                navigate("/dashboard");
            }
        } catch (error) {
            const failureReason = error.data?.detail || error.message;
            insertNotification("Error", `Can't update user: ${failureReason}`, "error");
        }
    };

    return (
        <>
            <GSPSPageWrapper className="show" role="dialog"
                show={show}
                centered
                size={"lg"}
                container={document.querySelector("#root .App")}
            >
                <GSPSPopUpHeader>
                    User Information
                </GSPSPopUpHeader>

                {user &&
                    <UserFormFields onSubmit={onSubmit} user={user} setShow={setShow} />
                }
            </GSPSPageWrapper>

        </>
    );
};
UserEditor.propTypes = {
    show: PropTypes.bool.isRequired,
    setShow: PropTypes.func.isRequired,
    userID: PropTypes.number.isRequired,
};
