import {getCorePrefix} from "Services/Endpoints";

const prefix = getCorePrefix();

export const API_LOCATIONS = {
    locations: `${prefix}locations/`,
    accountLocations: (id) => `${prefix}locations/?account_id=${id}`,
    locationsDropdown: `${prefix}locations-dropdown`,
    statusTypes: `${prefix}locations/status/types`,
};
