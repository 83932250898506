import React from "react";
import PropTypes from "prop-types";
import { FormControl, MenuItem, Select, FormHelperText } from "@mui/material";
import { GSPSContainer } from "Components/Common/GSPSLabeledInput.styles";
import { useTheme } from "styled-components";
import { Col } from "react-bootstrap";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';

export const GSPSControlledLabelledSelectField = ({
  inputID,
  selectableOptions,
  labelName,
  isBold = false,
  isRequired = false,
  value = "",
  onChangeCB = null,
  errors,
  isInvalid,
  disableAutoSorting
}) => {
  const theme = useTheme();
  const getErrorMessage = () => {
    return errors && errors[inputID] ? errors[inputID].message : "";
  };

  const sortedOptions = disableAutoSorting
    ? selectableOptions.sort((a, b) => (a.display_text < b.display_text ? -1 : 1))
    : selectableOptions;

  const handleChange = (event) => {
    const newValue = event.target.value;
    if (onChangeCB) {
      onChangeCB(newValue);
    }
  };

  return (
    <GSPSContainer>
      <div>
        {isBold ? <strong>{labelName}</strong> : labelName}
        {isRequired && <span style={{ color: "red" }}>*</span>}
      </div>
      <FormControl fullWidth error={isInvalid}>
        <Col>
          <Select
            id={inputID}
            value={value}
            onChange={handleChange}
            displayEmpty
            IconComponent={(props) => (
              <ArrowDropDownIcon {...props} />
            )}
            sx={{
              height: "38px",
              width: "100%",
              '.Mui-focused .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.color.link,
              },
              '& .MuiSelect-icon': {
                color: theme.color.link,
              },
              '&:hover .MuiOutlinedInput-notchedOutline': {
                borderColor: theme.color.link, 
              },
              '&:hover .MuiSelect-icon': {
                color: theme.color.link,
              }
            }}
          >
            <MenuItem value="" disabled>
              Select
            </MenuItem>
            {sortedOptions.map((optionObject) => (
              <MenuItem
                key={optionObject.key}
                value={optionObject.value}
                sx={{
                  '&.Mui-selected': {
                    backgroundColor: theme.color.primary,
                  },
                  '&.Mui-selected:hover': {
                    backgroundColor: theme.color.ctaHoverBg, 
                    color: "white",
                  },
                  '&:hover': {
                    backgroundColor: theme.color.ctaHoverBg,
                    color: "white",
                  }
                }}
              >
                {optionObject.display_text}
              </MenuItem>
            ))}
          </Select>
        </Col>
        {isInvalid && (
          <FormHelperText error>{getErrorMessage()}</FormHelperText>
        )}
      </FormControl>
    </GSPSContainer>
  );
};

GSPSControlledLabelledSelectField.propTypes = {
  inputID: PropTypes.string.isRequired,
  labelName: PropTypes.string.isRequired,
  isBold: PropTypes.bool,
  selectableOptions: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool]).isRequired,
      display_text: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    })
  ),
  isRequired: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChangeCB: PropTypes.func,
  errors: PropTypes.object,
  isInvalid: PropTypes.bool,
  disableAutoSorting: PropTypes.bool,
};
